import React from 'react';
import './App.scss';
import { Routes, Route } from "react-router-dom";


import NavBar from './components/NavBar/NavBar';
import AboutMe from './components/AboutMe/AboutMe';
import Home from './components/Home/Home';
import Oops from './components/Oops/Oops';
import Projects from './components/Projects/Projects';
import GetCompliment from './components/ComplimentGenerator/ComplimentGenerator';
import CrunchatizeMeCapn from './components/CrunchatizeMeCapn/CrunchatizeMeCapn';

//import { GetName } from './getName'


function App() {
  return (
    <>
      <div className="rainbow-background">
        <Routes>
          <Route path="/" element={<NavBar />} >
            <Route index element={<Home />} />
            <Route path="about" element={<AboutMe />} />
            <Route path="projects" element={<Projects />}>
              <Route path="compliment" element={<GetCompliment />}></Route>
              <Route path="chomp" element={<CrunchatizeMeCapn />}></Route>
            </Route>
            <Route path="*" element={<Oops />} />
          </Route>
        </Routes>
      </div>

    </>


  );

}



export default App;
