import React, { useState } from 'react'
import { complimentWords } from "./words"
import './compliment.scss'
const { nouns, adjectives } = complimentWords

export default function GetCompliment() {
  const [compliment, getKnopeCompliment] = useState('Click here for a well-deserved compliment à la Leslie Knope')

  const handleOnClick = () => () => {
    getKnopeCompliment(generateCompliment())
  }

  return (

    <div className="box p-2 is-clickable" onClick={handleOnClick()}>

      <div className="has-text-black is-size-5 has-text-weight-semibold p-2">
        <p>Hello, friend! {compliment}.</p>
      </div>
    </div>
  )
}

function shuffle(array) {
  array.sort(() => Math.random() - 0.5)
}

function generateCompliment(numAdjectives = 1) {
  numAdjectives = Math.floor(Math.random() * 4) + 1

  shuffle(adjectives)
  let complimentAdjectives = adjectives.slice(0, numAdjectives)
  let stringAdjectives = complimentAdjectives.join(', ')
  let firstLetter = stringAdjectives.charAt(0).toLowerCase();
  let vowels = ['a', 'e', 'i', 'o', 'u'];

  let indefiniteArticle = vowels.includes(firstLetter) ? "an" : "a";
  shuffle(nouns)
  let noun = nouns[0]
  return 'You are ' + indefiniteArticle + ' ' + stringAdjectives + ' ' + noun
}