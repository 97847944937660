import React from "react"

export default function Oops() {
    return (
        <div className="box">
            <div className="has-text-black has-text-centered">
                <p className="content is-size-5">This isn't even like...a place. <span className="has-text-weight-semibold">WHAT DID YOU DO??!!!</span></p>
                <div className="is-max-width-500 m-auto">
                    <figure className="image is-16by9">
                        <iframe
                        title="what are you doing here GIF"
                            className="has-ratio"
                            width="500"
                            height="250"
                            src="https://giphy.com/embed/pJ8toT2rZvxwdHcZsY"
                            frameborder="0"
                            alt="What are you doing here GIF"
                            allowfullscreen
                        ></iframe>
                    </figure>
                </div>
            </div>
        </div>
    )
}