export const complimentWords ={
	nouns:[
		 "alpaca",
		 "apple pie",
		 "babe",
		 "baked potato",
		 "balloon animal",
		 "bear",
		 "bumble bee",
		 "blueberry",
		 "bowl of pinecones",
		 "box of puppies",
		 "butterfly",
		 "bunny",
		 "cake",
		 "cakepop",
		 "camp fire",
		 "caribou",
		 "cat",
		 "caterpillar",
		 "celestial body",
		 "cheetah",
		 "child",
		 "chinchilla",
		 "cookie",
		 "cupcake",
		 "cutie",
		 "cutie pie",
		 "creature",
		 "daffodil",
		 "daisy",
		 "deer",
		 "defender of justice",
		 "diamond",
		 "dogfish",
		 "doll",
		 "dolphin",
		 "dove",
		 "dragonfly",
		 "dream come true",
		 "elk",
		 "extra large popcorn",
		 "fairy",
		 "finch",
		 "firefly",
		 "flamingo",
		 "flower crown",
		 "font of wisdom",
		 "fox",
		 "freight train",
		 "galaxy",
		 "gazelle",
		 "gem",
		 "giraffe",
		 "glass of juice",
		 "goldfish",
		 "goody bag",
		 "goose",
		 "gourd",
		 "grapefruit",
		 "grasshopper",
		 "greatest human being ever invented",
		 "hellicopter",
		 "hot air balloon",
		 "houseplant",
		 "human",
		 "hummingbird",
		 "ice sculpter",
		 "jaguar",
		 "jellyfish",
		 "jewel",
		 "kangaroo",
		 "kitten",
		 "koala",
		 "kookabura",
		 "land-mermaid",
		 "lark",
		 "lemur",
		 "leopard",
		 "lightbulb of truth",
		 "lil cutie",
		 "lion",
		 "loris",
		 "mannequin",
		 "masterpiece",
		 "meerkat",
		 "mermaid",
		 "miniature horse",
		 "minx",
		 "monkey",
		 "moonstone",
		 "moose",
		 "moth",
		 "mouse",
		 "musk ox",
		 "narwhal",
		 "newborn baby",
		 "nightingale",
		 "octopus",
		 "otter",
		 "owl",
		 "oyster",
		 "panther",
		 "parrot",
		 "partridge",
		 "peach",
		 "pearl",
		 "pegasus",
		 "penguin",
		 "pile of pancakes",
		 "pioneer",
		 "plum pudding",
		 "pony",
		 "porpoise",
		 "pot of gold",
		 "queen bee",
		 "rabbit",
		 "rainbow of joy",
		 "raven",
		 "reindeer",
		 "rhinoceros",
		 "sand dollar",
		 "scholar",
		 "seahorse",
		 "solar flare",
		 "songbird",
		 "smarty pants",
		 "smoothie",
		 "snack",
		 "snail",
		 "space unicorn",
		 "sparrow",
		 "spongecake",
		 "squirrel",
		 "stack of waffles",
		 "star",
		 "starling",
		 "steward of hope",
		 "strawberry shortcake",
		 "sphinx",
		 "spork",
		 "sugar cube",
		 "sun",
		 "sunfish",
		 "sunflower",
		 "supernova",
		 "superstar",
		 "swan",
		 "tiger",
		 "tiny bus driver",
		 "tortoise",
		 "treasure chest",
		 "treat",
		 "tree shark",
		 "turtle",
		 "unicorn",
		 "uncarved pumpkin",
		 "well of ideas",
		 "wildcat",
		 "wizard",
		 "yam"
	],
	adjectives:[
		 "accomplished",
		 "adaptable",
		 "adorable",
		 "adored",
		 "adventurous",
		 "affable",
		 "affectionate",
		 "agreeable",
		 "altruistic",
		 "amazing",
		 "ambitious",
		 "amiable",
		 "amicable",
		 "amusing",
		 "angelic",
		 "appreciated",
		 "attractive",
		 "audacious",
		 "authentic",
		 "awesome",
		 "balanced",
		 "beautiful",
		 "beloved",
		 "bewitching",
		 "blissful",
		 "bold",
		 "brave",
		 "breathtaking",
		 "bright",
		 "brilliant",
		 "broad-minded",
		 "calm",
		 "capable",
		 "careful",
		 "caring",
		 "centered",
		 "charismatic",
		 "charming",
		 "cheeky",
		 "cheerful",
		 "cherished",
		 "clever",
		 "communicative",
		 "compassionate",
		 "confident",
		 "conscientious",
		 "considerate",
		 "courageous",
		 "courteous",
		 "creative",
		 "cute",
		 "daring",
		 "dazzling",
		 "decisive",
		 "dedicated",
		 "delightful",
		 "desirable",
		 "determined",
		 "diligent",
		 "divine",
		 "easygoing",
		 "effervescent",
		 "emotional",
		 "empathic",
		 "empathetic",
		 "empowered",
		 "enchanting",
		 "energetic",
		 "energized",
		 "enlightened",
		 "enlivened",
		 "enthusiastic",
		 "eternal",
		 "excellent",
		 "excited",
		 "exhilarating",
		 "expansive",
		 "extra",
		 "exquisite",
		 "extraordinary",
		 "exuberant",
		 "fabulous",
		 "fair-minded",
		 "faithful",
		 "fancy",
		 "fantastic",
		 "fascinating",
		 "fearless",
		 "fiery",
		 "focused",
		 "forgiving",
		 "fortuitous",
		 "free-spirited",
		 "fresh",
		 "friendly",
		 "frolicsome",
		 "fun-loving",
		 "funny",
		 "generous",
		 "genial",
		 "genius",
		 "gentle",
		 "genuine",
		 "giving",
		 "glittering",
		 "glorious",
		 "glowing",
		 "good",
		 "healthy",
		 "graceful",
		 "gracious",
		 "grateful",
		 "great",
		 "gregarious",
		 "grounded",
		 "happy-hearted",
		 "hard-working",
		 "harmonious",
		 "heartwarming",
		 "heavenly",
		 "helpful",
		 "high quality",
		 "high-spirited",
		 "honest",
		 "hopeful",
		 "imaginative",
		 "incomparable",
		 "incredible",
		 "independent",
		 "ineffable",
		 "innovative",
		 "insightful",
		 "inspirational",
		 "inspired",
		 "intellectual",
		 "intelligent",
		 "intuitive",
		 "inventive",
		 "invigorated",
		 "irridescent",
		 "irresistible",
		 "jolly",
		 "jovial",
		 "joyful",
		 "joyous",
		 "jubilant",
		 "kind",
		 "kind-hearted",
		 "kissable",
		 "knowledgeable",
		 "lively",
		 "lovable",
		 "lovely",
		 "loving",
		 "loyal",
		 "lucky",
		 "lustrous",
		 "luxurious",
		 "magical",
		 "magnificent",
		 "marvelous",
		 "memorable",
		 "mind-blowing",
		 "miraculous",
		 "mirthful",
		 "modest",
		 "mythical",
		 "nice",
		 "noble",
		 "open-hearted",
		 "open-minded",
		 "optimistic",
		 "opulent",
		 "original",
		 "outstanding",
		 "passionate",
		 "patient",
		 "peaceful",
		 "perfect",
		 "perky",
		 "peppy",
		 "persistent",
		 "pioneering",
		 "placid",
		 "playful",
		 "polite",
		 "positive",
		 "powerful",
		 "practical",
		 "precious",
		 "pro-active",
		 "prosperous",
		 "quick-witted",
		 "radient",
		 "rainbow-infused",
		 "really really ridiculously good looking",
		 "receptive",
		 "regal",
		 "reliable",
		 "remarkable",
		 "resilient",
		 "resourceful",
		 "resplendent",
		 "rich",
		 "sassy",
		 "saucy",
		 "scintillating",
		 "self-accepting",
		 "self-disciplined",
		 "self-loving",
		 "sensational",
		 "sensible",
		 "sensitive",
		 "serene",
		 "shining",
		 "shy",
		 "sincere",
		 "smart",
		 "sociable",
		 "soulful",
		 "sparkly",
		 "special",
		 "spectacular",
		 "splendid",
		 "stellar",
		 "strong",
		 "stupendous",
		 "sublime",
		 "successful",
		 "sumptuous",
		 "sunny",
		 "super",
		 "sympathetic",
		 "thoughtful",
		 "thriving",
		 "tidy",
		 "tough",
		 "tranquil",
		 "triumphant",
		 "trustworthy",
		 "unbelievable",
		 "understanding",
		 "unique",
		 "unreal",
		 "uplifted",
		 "valuable",
		 "versatile",
		 "vibrant",
		 "vivacious",
		 "warm",
		 "warmhearted",
		 "welcomed",
		 "wholehearted",
		 "wise",
		 "witty",
		 "wonderful",
		 "wondrous",
		 "young-at-heart",
		 "youthful",
		 "zappy",
		 "zestful"
	]
,
	officialCompliments:[
		 "you poetic, noble land-mermaid.",
		 "you beautiful, talented, brilliant, powerful muskox.",
		 "you opalescent tree shark.",
		 "you perfect sunflower.",
		 "you rainbow infused space unicorn.",
		 "you beautiful rule-breaking moth.",
		 "you beautiful, naive, sophisticated newborn baby.",
		 "you tricky minx!",
		 "you beautiful spinster, I will find you love.",
		 "you beautiful tropical fish, you\\'re smart as a whip, and cool under pressure.",
		 "you beautiful, glowing sun goddess.",
		 "you beautiful, sassy mannequin come to life.",
		 "you beautiful, talented, brilliant, powerful musk-ox.",
		 "you cunning, pliable, chestnut-haired sunfish.",
		 "you're so sweet and innocent and pretty.",
		 "you're a genius! Your brain is almost as perfect as your face.",
		 "you vivacious rainbow of joy.",
		 "priceless expensive gem.",
		 "flourecent lightbulb of truth.",
		 "you're the greatest human being ever invented.",
		 "delicious stack of waffles."
	]
}