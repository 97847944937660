import React from 'react';

export default function Home() {
    return (
        < >
            <div className="box">
                <p className="title is-size-0 is-size-1-mobile">WELCOME!</p>
                <p className="subtitle is-size-3 is-size-4-mobile has-text-color-dark-gray">I'M SO GLAD YOU'RE HERE</p>
                <p className="is-size-5">Congratulations! You've found the best place on the internet.
                </p>
            </div>
            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1020 560"  >
                <g transform="scale(2.8,2.5) translate(-250,-320)"><path fill="#E0E9F6" d="M432,330.728c-77.292,0-139.836,62.543-139.836,139.836h77.705c0-34.416,27.786-62.131,62.131-62.131  c34.345,0,62.131,27.715,62.131,62.131h77.705C571.836,393.272,509.292,330.728,432,330.728z" />
                    <path fill="#9ED089" d="M525.197,470.564h-15.492c0-42.876-34.758-77.705-77.705-77.705c-42.947,0-77.705,34.829-77.705,77.705  h-15.492c0-51.478,41.649-93.268,93.197-93.268C483.549,377.297,525.197,419.087,525.197,470.564z" />
                    <path fill="#FFCD78" d="M540.771,470.564h-15.573c0-51.478-41.648-93.268-93.197-93.268c-51.548,0-93.197,41.79-93.197,93.268  H323.23c0-60.089,48.681-108.7,108.77-108.7C492.09,361.865,540.771,410.475,540.771,470.564z" />
                    <path fill="#F89A54" d="M556.273,470.564h-15.503c0-60.089-48.681-108.7-108.77-108.7c-60.089,0-108.77,48.611-108.77,108.7h-15.502  c0-68.69,55.582-124.273,124.273-124.273C500.691,346.291,556.273,401.874,556.273,470.564z" />
                    <path fill="#EF444E" d="M571.836,470.564h-15.562c0-68.69-55.582-124.273-124.273-124.273c-68.69,0-124.273,55.582-124.273,124.273  h-15.563c0-77.292,62.544-139.836,139.836-139.836S571.836,393.272,571.836,470.564z" />
                    <path fill="#E0E9F6" d="M594.572,472.801c19.889-18.988-13.354-37.844-27.658-20.111c-12.507-18.931-47.823-14.388-53.199,8.623  c-9.004-15.539-44.177-11.515-31.499,20.74c-28.95,8.817-3.471,59.468,28.023,36.498c8.921,22.698,67.744,17.712,73.958-5.136  C610.886,516.88,625.574,482.085,594.572,472.801z" />
                    <path fill="#E0E9F6" d="M269.427,472.801c-19.888-18.988,13.355-37.844,27.659-20.111c12.506-18.931,47.823-14.388,53.199,8.623  c9.004-15.539,44.177-11.515,31.499,20.74c28.951,8.817,3.471,59.468-28.023,36.498c-8.921,22.698-67.744,17.712-73.958-5.136  C253.115,516.88,238.425,482.085,269.427,472.801z" /></g>
            </svg>
        </>)

}